import React from 'react'
import Header from '../components/header/Header'
import styled from '@emotion/styled'

import {MobileDashboard} from "../components/dashboard/MobileDashboard";
import {PcDashboard} from "../components/dashboard/PcDashboard";

const DashboardContainer = styled('div')`background-color: ${props => props.theme.dashBoard};`;
const Dashboard = (props) => {

    //
    // function unwrap(node) {
    // 	node.replaceWith(...node.childNodes);
    // }

    return (
        <DashboardContainer className="dashboard-container">

            {console.log(props.data)}

            <Header updateData={props.updateData} currentID={props.currentID} currentData={props.currentData}
                    data={props.data}/>
            <div className="dashboard-wrapper">
                <div className="dashboard-wrapper-pc">
                    <PcDashboard updateData={props.updateData} currentID={props.currentID}
                                 currentData={props.currentData} data={props.data} loadAnimation={props.loadAnimation}/>
                </div>
                <div className="dashboard-wrapper-mobile">
                    <MobileDashboard updateData={props.updateData} currentID={props.currentID}
                                     currentData={props.currentData} data={props.data}
                                     loadAnimation={props.loadAnimation}/>
                </div>
            </div>
        </DashboardContainer>
    )
};

export default Dashboard
