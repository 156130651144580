import * as React from 'react'
import ServerDetails from './cards/ServerDetails'
import DiskDetails from './cards/DiskDetails'
import RamDetails from './cards/RamDetails'
import CoreDetails from './cards/CoreDetails'
import ClockDetails from './cards/ClockDetails'
import UptimeDetails from './cards/UptimeDetails'
import CpuDetails from './cards/CpuDetails'
import OsDetails from './cards/OsDetails'
import PingDetails from './cards/PingDetails'

export const MobileDashboard = (props) => {
    return (
        <React.Fragment>
            <RamDetails  data={props.currentData}/>
            <CoreDetails  data={props.currentData}/>
            <ClockDetails
                          data={props.currentData}/>
            <ServerDetails data={props.currentData}/>
            <DiskDetails  data={props.currentData}/>
            <UptimeDetails  data={props.currentData}/>
            <CpuDetails  data={props.currentData}/>
            <OsDetails data={props.currentData}/>
            <PingDetails data={props.currentData}/>
        </React.Fragment>
    );
};
