import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from '../../../darkmode/ThemeContext'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'

const data = [
	{
		date: '11-01-2020', Up: 1,
	},
	{
		date: '12-01-2020', Up: 1,
	},
	{
		date: '13-01-2020', Up: 1,
	},
	{
		date: '14-01-2020', Up: 0,
	},
	{
		date: '15-01-2020', Up: 0,
	},
	{
		date: '16-01-2020', Up: 1,
	},
	{
		date: '17-01-2020', Up: 1,
	},
]

const Cards = styled('div')`background-color: ${props => props.theme.card};
-webkit-box-shadow:${props => props.theme.shadow};
	tspan {
		color:white !important;
		fill:${props => props.theme.font}!important;
	}

`

const UptimeDetails = (props) => {

	const themeState = useTheme()
	const darkGridColor = themeState.dark ? '#400011' : '#FAD2DA'
	// const darkStrokeColor = themeState.dark ? "#FAD2DA" : "#E32249";
	return (
		<Cards style={{ transitionDelay: `${props.delay}ms` }} className={`information-cards-container ${props.name}`}>
			<div className="graph-container">
				<div className="main-stat">
					<h3>Uptime</h3>
				</div>
				<div className="graph-wrapper">
					<ResponsiveContainer width="80%"  height={500}>
						<AreaChart
							width="99%"  height="99%"
							animationBegin={props.delay}
							data={data}
							margin={{
								top: 0, right: 0, left: 0, bottom: 0,
							}}
						>
							<CartesianGrid strokeDasharray="3 3"/>
							<XAxis dataKey="date"/>
							<YAxis/>
							<Tooltip/>
							<Area type="monotone" dataKey="Up" stroke="#E32249" strokeWidth={2} fill={darkGridColor}/>
						</AreaChart>
					</ResponsiveContainer>
				</div>
			</div>
		</Cards>
	)
}
export default UptimeDetails
