import React, { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import TransitionGroup from 'react-transition-group/cjs/TransitionGroup'
import CSSTransition from 'react-transition-group/cjs/CSSTransition'
import styled from '@emotion/styled'
// import Button from "../components/general/Button";
import { formatBytes } from '../components/global/Functions'
import { useTheme } from '../darkmode/ThemeContext'

const ListContainer = styled('div')`background-color: ${props => props.theme.listColor};
-webkit-box-shadow:${props => props.theme.shadow}`;

const ListTabs = styled('div')`background-color:  ${props => props.theme.listTabs};
    li 
    {
        &:hover
        {
            background-color:${props => props.theme.listColor};
            cursor:pointer;
        }
        &.active {background-color: ${props => props.theme.listColor};}
    }
`;

const ListHeader = styled('div')`background-color: ${props => props.theme.listHeader};`;

const SearchBox = styled('div')`border: ${props => props.theme.searchBoxBorder}; 
        background-color:${props => props.theme.searchBox};
        svg{path{fill:${props => props.theme.font};}}
        input 
        {
           color:${props => props.theme.font};
           background-color:${props => props.theme.searchBox};
        }
`;

const ListWrapper = styled('div')`background-color: ${props => props.theme.listWrapper};
border-color:${props => props.theme.listBorder}`;

const Home = (props) => {

    const themeState = useTheme();
    const [showActiveDashboards, setOnlineDashboards] = useState(true);
    const [activeIndex, setActiveIndex] = useState(null);
    const [value, setValue] = useState('');
    const TabBar = useRef();
    const [data] = useState(props.data)

    const activeDashboards = () => {
        setOnlineDashboards(true);
        // close list if navigating to different section
        if (!showActiveDashboards) {
            setActiveIndex(null)
        }
    };

    const inActiveDashboards = () => {
        setOnlineDashboards(false);
        // close list if navigating to different section
        if (showActiveDashboards) {
            setActiveIndex(null);
        }
    };

    const filterResults = (input) => {
        return function (x) {
            return x.serviceName.toString().toLowerCase().includes(input.toLowerCase()) || !input
        }
    };

    const filterActive = (data, condition) => {
        let array = [];
        data.forEach(function (object) {
            if (object.active === condition) {
                array.push(object)
            }
        });
        return array
    };

    // const openList = (index) => {
    //     (activeIndex === index ? setActiveIndex(null) : setActiveIndex(index))
    // };

    const handleText = (input) => {
        setValue(input.target.value)
    };

    // const changeActivity = (index, response) => {
    //     let _data = filterActive(data, showActiveDashboards);
    //     let newData=[]
    //     console.log(_data)
    //     for (let i=0; i < _data.length; i++) {
    //         if(i === activeIndex) {
    //             console.log(i, activeIndex)
    //             props.data[i].active = response;
    //         }
    //         newData.push(props.data[i])
    //     }
    //     updateData(newData);
    //     console.log(newData)
    //     setActiveIndex(null)
    // };

    return (
        <div className="home-container">
            <h2>Demo Application - Dashboard</h2>
            <ListContainer className="dashboard-list-container">
                <ListTabs className="dashboard-list-tabs">
                    <li onClick={activeDashboards} className={showActiveDashboards ? 'active left' : 'left'}>
                        <p>Active</p></li>
                    <li onClick={inActiveDashboards} className={showActiveDashboards ? null : 'active'}><p>Inactive</p>
                    </li>
                </ListTabs>
                <div className="dashboard-inner-wrapper">
                    <div className="dashboard-list-search-container">
                        <SearchBox className="dashboard-list-search">
                            <input type="text" onChange={(e) => {
                                handleText(e)
                            }} placeholder="Search..."
                                   onFocus={(e) => e.target.placeholder = ''}
                                   onBlur={(e) => e.target.placeholder = 'Search...'}
                            />
                            <FontAwesomeIcon icon={faSearch}/>
                        </SearchBox>
                    </div>
                    <div className="dashboard-list-body">
                        <ListHeader ref={TabBar} className="dashboard-list-header">
                            <span className="dashboard-list-cell"><p>Status </p></span>
                            <span className="dashboard-list-cell"><p>ID</p></span>
                            <span className="dashboard-list-cell"><p>Device Name </p></span>
                            <span className="dashboard-list-cell"><p>Application Type </p></span>
                            <span className="dashboard-list-cell"><p>Ram</p></span>
                            <span className="dashboard-list-cell"><p>Ram Usage</p></span>
                            <span className="dashboard-list-cell"><p>Uptime</p></span>
                            <span className="dashboard-list-cell"><p>Updated</p></span>
                            <span className="dashboard-list-cell"><p>IP</p></span>
                            {/*<span className="dashboard-list-cell"><p>Actions</p></span>*/}
                        </ListHeader>
                        <TransitionGroup component={null}>
                            {filterActive(data, showActiveDashboards).filter(filterResults(value)).map((data, index) => {
                                return (

                                <CSSTransition timeout={700} classNames="fade-in-list" key={data.macAddress}>
                                        <ListWrapper
                                            className={`dashboard-list-wrapper ${activeIndex === index
                                                ? 'open'
                                                : null}`}>
                                            <div className="dashboard-list-inner-wrapper">
                                                <div className="dashboard-list-cell">
                                                    <p className={data.online ? 'online' : 'offline'}>{data.online
                                                        ? 'ONLINE'
                                                        : 'OFFLINE'}</p>
                                                </div>
                                                <div className="dashboard-list-cell">
                                                    <p>{data.macAddress}</p>
                                                </div>
                                                <div className="dashboard-list-cell">
                                                    <p>{data.deviceName}</p>
                                                </div>
                                                <div className="dashboard-list-cell">
                                                    <p>{data.applicationType}</p>
                                                </div>
                                                <div className="dashboard-list-cell">
                                                    <p>{formatBytes(data.usedRam)} / {formatBytes(data.totalRam)}</p>
                                                </div>
                                                <div className="dashboard-list-cell">
                                                    <p key={data.usedRam}
                                                       className={`animated-text`}>
                                                        {(data.usedRam / data.totalRam * 100).toFixed(2)}%
                                                    </p>
                                                </div>

                                                <div className="dashboard-list-cell">
                                                    <p className={`animated-text ${themeState.dark ? "positive-dark" : "positive-light"}`}
                                                       key={data.uptime}>{data.uptime}</p>
                                                </div>
                                                <div className="dashboard-list-cell">
                                                    <p>{data.updated}</p>
                                                </div>
                                                <div className="dashboard-list-cell">
                                                    <p>{data.ip}</p>
                                                </div>
                                                {/*<div className="dashboard-list-cell">*/}
                                                    {/*<p className="link" onClick={() => {*/}
                                                        {/*openList(index)*/}
                                                    {/*}}>actions</p>*/}
                                                {/*</div>*/}
                                            </div>
                                            {/*{*/}
                                                {/*showActiveDashboards ? (*/}
                                                        {/*<div className="list-action">*/}
                                                            {/*<Button*/}
                                                                {/*buttonType="caution"*/}
                                                                {/*onClick={() => {*/}
                                                                    {/*changeActivity(index, false)*/}
                                                                {/*}}*/}
                                                                {/*title="Set Inactive"/>*/}
                                                        {/*</div>*/}
                                                    {/*) :*/}
                                                    {/*(*/}
                                                        {/*<div className="list-action">*/}
                                                            {/*<Button*/}
                                                                {/*buttonType="positive"*/}
                                                                {/*onClick={() => {*/}
                                                                    {/*changeActivity(index, true)*/}
                                                                {/*}}*/}
                                                                {/*title="Set active"/>*/}
                                                        {/*</div>*/}
                                                    {/*)*/}
                                            {/*}*/}
                                        </ListWrapper>
                                    </CSSTransition>
                                )
                            })}
                        </TransitionGroup>
                    </div>
                </div>
            </ListContainer>
        </div>
    )
};
export default Home
