import React from "react";
import {useTheme} from "../../darkmode/ThemeContext";

const DarkMode = () => {
    const themeState = useTheme();
    const toggle = () => {
        themeState.toggle();
    };
    return(
        <div className="dark-mode-button-container">
            <div className="switch" onClick={toggle}>
                <input type="checkbox"/>
                <span className={`dark-slider round ${themeState.dark ? "dark" : "light"}`}>
                    <p> Light </p>
                    <p> Dark </p>
                </span>
            </div>
        </div>
    )
};
export default DarkMode;
