import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import './App.scss'
import SideNav from './components/sidenav/SideNav'
import Dashboard from './routes/Dashboard'
import styled from '@emotion/styled'
import LoadingScreen from './components/Loaders/LoadingScreen'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Home from './routes/Home'
import MobileHeader from './components/header/MobileHeader'
import { dummyData } from './components/global/DummyData'

const AppContainer = styled('div')`a, p, h1, h2, h3, h4, h5 {color: ${props => props.theme.font};}
background-color: ${props => props.theme.dashBoard};
body{
		background-color: ${props => props.theme.dashBoard};
}`;

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: dummyData,
            appLoaded: false,
            currentData: dummyData[0],
            currentID: 0,
            loadAnimation: false,
            lastFetch:new Date().toLocaleTimeString(),
        }
    }

    updateData = (id) => {
        this.setState({currentID: id, currentData: this.state.data[id]})
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ appLoaded:true})
        }, 3000)

        setTimeout(() => {
            this.setState({  loadAnimation:true,})
        }, 4000)
    }

    componentWillUnmount() {
    }

    render() {
        const {data, appLoaded, currentData, currentID, loadAnimation, lastFetch} = this.state;
        return (
            <AppContainer className="App">
                <TransitionGroup component={null}>
                    {appLoaded ? (
                        <CSSTransition
                            in={appLoaded}
                            key="content"
                            timeout={700}>
                            <div className="content">
                                <div className="side-nav-outer" style={{position: 'relative'}}>
                                    <SideNav date={lastFetch}/>
                                </div>
                                <MobileHeader date={lastFetch}
                                              updateData={this.updateData} currentID={currentID}
                                              currentData={currentData} data={data}/>
                                <Switch>
                                    <Route path="/" exact render={(props) => <Home {...props} data={data}/>}/>
                                    <Route path="/dashboard" exact
                                           render={(props) => <Dashboard {...props}
                                                                         data={data}
                                                                         loadAnimation={loadAnimation}
                                                                         updateData={this.updateData}
                                                                         currentID={currentID}
                                                                         currentData={currentData}/>}/>
                                    {/*<Route component={NoMatch}/>*/}
                                </Switch>
                            </div>
                        </CSSTransition>
                    ) : (
                        <CSSTransition
                            in={!appLoaded}
                            classNames="loading-screen"
                            timeout={700}
                            key="splash-screen"
                            exit>
                            <div>
                                <LoadingScreen/>
                            </div>
                        </CSSTransition>
                    )}
                </TransitionGroup>
            </AppContainer>
        )
    }
}

export default App
