import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
import unregisterServiceWorker from './registerServiceWorker';
import Analytics from 'react-router-ga';
import {CookiesProvider} from 'react-cookie';
import { ThemeProvider } from "./darkmode/ThemeContext";
import 'semantic-ui-css/semantic.min.css';

import App from './App';
import ScrollToTop from "./components/general/ScrollToTop";
import { Global, css } from '@emotion/core';

ReactDOM.render(
	<ThemeProvider>
		<Global
			styles={css`
        body { background-color: ${props => props.theme.dashBoard}};
        }
      `}
		/>
	<Router>
		<Analytics id="UA-90768292-3">
			<ScrollToTop>
				<CookiesProvider>
					<App/>
				</CookiesProvider>
			</ScrollToTop>
		</Analytics>
	</Router>
	</ThemeProvider>,
	document.getElementById('root')
);

unregisterServiceWorker();
