import React from 'react'
import Logo from '../header/Logo'
import styled from '@emotion/styled'
import GraphLoader from './GraphLoader'

const Wrapper = styled('div')`
		background-color: ${props => props.theme.loadScreen};
		svg{path {fill:  ${props => props.theme.logo};}
		width:80px;
		margin:30px 0 0 10px;
		}
`;

function LoadingScreen (props) {
	return (
		<Wrapper className="loading-screen-container">
			<GraphLoader/>
			<div className="loading-screen-text">
				<p>Powered by</p>
				{/*<p className="asrr-font">ASRR</p>*/}
				<Logo/>
			</div>
		</Wrapper>
	)
}

export default LoadingScreen
