 export const dummyData =[
    {
        "macAddress": "ASRR-Node-1-ABC",
        "registered": "2020-01-17 04:17:42",
        "lastRestart": "2020-01-17 04:21:01",
        "updated": new Date().toLocaleTimeString(),
        "ping": 80,
        "ip": "13.0.1.57",
        "serviceName": "ASRR-Node-1",
        "deviceName": "ASRR-Node-1",
        "port": 1222,
        "os": "Windows",
        "applicationType": "ReactJs",
        "usedRam": 20012012013,
        "totalRam": 21123123123,
        "cpuLoad": 0,
        "coreCount": 4,
        "clockSpeed": null,
        "maxClockSpeed": null,
        "totalDiskSpace": 21223123123,
        "usedDiskSpace": 1123123123,
        "requests": 0,
        uptime:2000,
        online:true,
        active:true

    },
    {
        "macAddress": "ASRR-Node-2-ABC",
        "registered": null,
        "lastRestart": "2020-01-17 04:17:56",
        "updated": new Date().toLocaleTimeString(),
        "ping": 100,
        "ip": "19.0.0.1",
        "serviceName": "ASRR-Node-2",
        "deviceName": "ASRR-Node-2",
        "port": 8080,
        "os": "Windows",
        "applicationType": "ReactJs",
        "usedRam": 21112121113,
        "totalRam": 31123123123,
        "cpuLoad": 0,
        "coreCount": 6,
        "clockSpeed": null,
        "maxClockSpeed": null,
        "totalDiskSpace": 121223123123,
        "usedDiskSpace": 41123123123,
        "requests": 0,
        uptime:10400,
        online:true,
        active:true
    },
    {
        "macAddress": "ASRR-Node-3-ABC",
        "registered": null,
        "lastRestart": "2020-01-17 04:21:01",
        "updated": new Date().toLocaleTimeString(),
        "ping": 30,
        "ip": "16.0.0.47",
        "serviceName": "ASRR-Node-3",
        "deviceName": "ASRR-Node-3",
        "port": 8000,
        "os": "Linux",
        "applicationType": "Spring Boot",
        "usedRam": 0,
        "totalRam": 123123123,
        "cpuLoad": 0,
        "coreCount": 4,
        "clockSpeed": 4,
        "maxClockSpeed": 4.5,
        "totalDiskSpace": 35123123123,
        "usedDiskSpace": 5123123123,
        "requests": 0,
        uptime:234000,
        online:false,
        active:false
    }
];

