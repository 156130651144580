const sideBarDark = "#121729";
const sideBarLight = "#FCFCFC";

const headerDark = "#0c101b";
const headerLight = "#FCFCFC";

const mobileHeaderDark = "#0f0f26";
const mobileHeaderLight = "#fafafa";

const mobileMenuHeaderDark = "#122032";
const mobileMenuHeaderLight = "#FCFCFC";

const fontDark = "#1a1a1a";
const fontLight = "#ffffff";

const dashboardDark = "#0c101b";
const dashboardLight = "#EEEEEE";

const listDark = "#121729";
const listLight = "white";

const listWrapperDark = "#0d0d1d";
const listWrapperLight = "#ffffff";

const listBorderDark = "#070710";
const listBorderLight = "#eeeeee";

const listHeaderDark = "#0f0f26";
const listHeaderLight = "#EEEEEE";

const cardsDark = "#121729";
const cardsLight = "#ffffff";

const searchBoxBorderLight= "1px solid #eeeeee";
const searchBoxBorderDark= "0.5px solid #ffffff";

const searchBoxLight= "#ffffff";
const searchBoxDark= "#121729";

const loadscreenDark = "#0c101b";
const loadscreenLight = "#ffffff";

const logoDark = "#0c101b";
const logoLight = "#ffffff";

const borderDark = "#0c101b";
const borderLight = "#d6d6d6";

const lightShadow = "-webkit-box-shadow: 0px 12px 6px -1px rgba(179,179,179,1);";
const darkShadow = "-webkit-box-shadow: 0px 3px 12px -1px rgba(41,41,41,1);";

const lightTheme = {
    sideBar:sideBarLight,
    dashBoard: dashboardLight,
    card:cardsLight,
    font:fontDark,
    loadScreen:loadscreenLight,
    logo:logoDark,
    shadow:lightShadow,
    header:headerLight,
    mobileHeader:mobileHeaderLight,
    mobileMenu:mobileMenuHeaderLight,
    borderColor:borderLight,
    listColor:listLight,
    listHeader:listHeaderLight,
    searchBoxBorder: searchBoxBorderLight,
    searchBox: searchBoxLight,
    listTabs:listHeaderLight,
    listWrapper:listWrapperLight,
    listBorder:listBorderLight
};

const darkTheme = {
    sideBar:sideBarDark,
    font:fontLight,
    card:cardsDark,
    dashBoard: dashboardDark,
    loadScreen:loadscreenDark,
    logo:logoLight,
    shadow:darkShadow,
    header:headerDark,
    mobileHeader:mobileHeaderDark,
    mobileMenu:mobileMenuHeaderDark,
    borderColor:borderDark,
    listColor:listDark,
    listHeader:listHeaderDark,
    searchBoxBorder: searchBoxBorderDark,
    searchBox: searchBoxDark,
    listTabs:dashboardDark,
    listWrapper:listWrapperDark,
    listBorder:listBorderDark







};

const theme = mode => ( mode === "dark" ? darkTheme : lightTheme);

export default theme;
