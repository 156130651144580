import React from "react";
import styled from '@emotion/styled'
import Logo from '../header/Logo'
import NavLink from "react-router-dom/NavLink";
import DarkMode from "../general/DarkMode";

const SideBar = styled('div')`background-color: ${props => props.theme.sideBar};
		svg{path {fill:  ${props => props.theme.logo};}}
		border-right-color:  ${props => props.theme.borderColor};
`;

const SideNav = (props) => {

    return (
        <SideBar className="side-nav-container">
            <div className="side-nav-logo">
				<Logo/>
			</div>
            <div className="side-nav-menu">
				<div className="side-nav-darkmode">
					<DarkMode/>
				</div>
				<NavLink to="/" activeClassName="selected-link" exact>
                    <li>Home</li>
                </NavLink>
                <NavLink to="/dashboard" activeClassName="selected-link" exact>
                    <li>Dashboard</li>
                </NavLink>
            </div>
            <div className="side-nav-date">
                <p>Last fetch: {props.date}</p>
            </div>
        </SideBar>
    )
};

export default SideNav
