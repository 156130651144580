import React from 'react'
import NavLink from "react-router-dom/NavLink";
function Logo () {
	return (
		<NavLink to="/" exact>
		<svg className="logo" xmlns="http://www.w3.org/2000/svg" width="560" height="216" viewBox="18.5 11 560 216">
			<g transform="translate(0.000000,227.000000) scale(0.100000,-0.100000)">
				<path fill="none" d="M2175,2042.998c-116.001-15-232.002-71.997-322.002-156.997C1728.999,1770,1667.002,1628.999,1666.001,1465
		c-1.001-48.999,1.997-110,6.001-135c5.996-32.998-41.001,51.001-180,327.998L1305,2030h-35h-35l-478.999-959.004
		c-470-938.994-478.999-958.994-467.002-990L302.002,50h193.999H690l288.999,577.998L1267.998,1205l128.003-257.002L1525,690
		h193.999c173.003,0,195,2.002,207.002,17.998c6.997,9.004,13.999,25,13.999,35c0,9.004-37.002,90-81.001,180L1777.002,1085
		l71.997-67.002C1922.002,947.998,1995,905,2090,875c46.001-15,107.998-19.004,386.001-24.004L2807.002,845l35.996-25
		c106.001-70.996,128.003-207.998,49.004-302.002c-29.004-35-94.004-67.002-133.003-67.998c-52.998,0-58.999-20.996-58.999-202.002
		c0-207.002,0-207.002,117.998-190c149.004,21.006,265,82.002,362.998,191.006C3290,367.998,3334.004,485,3334.004,650
		c0,117.998-16.006,187.998-67.002,285c-41.006,77.998-164.004,201.001-242.002,242.002c-122.002,63.999-138.999,65.996-495,70.996
		c-345,4.004-352.998,5-407.998,56.001c-82.002,73.999-82.002,217.002,0,293.003C2150,1623.999,2211.001,1650,2242.998,1650
		c51.001,0,57.002,22.002,57.002,202.002C2300,2057.002,2298.999,2058.999,2175,2042.998z"/>
				<path fill="none" d="M2782.002,2025c-7.002-13.999-12.002-30-12.002-35c0-17.998,153.999-276.001,182.998-307.002l31.001-32.998
		h348.999c231.006,0,362.002-3.999,387.002-11.001c55-16.997,130-76.997,165-132.998c27.002-45,30-57.002,30-135
		c0-75-2.998-92.002-27.002-132.002c-28.994-50-78.994-97.998-130.996-126.001c-27.002-15-62.998-18.994-219.004-20.996
		c-268.994-5-265.996,27.998-35.996-370C3602.001,547.998,3730,325.996,3785.996,230l102.002-175l200-2.998L4287.001,50L4300,80
		c12.002,29.004,7.002,40-172.998,354.004l-185,325l57.002,35c123.994,76.992,240.996,226.992,286.992,369.995
		c22.002,67.002,27.002,101.001,28.008,196.001c0.996,135-12.002,196.001-64.004,305c-65,133.999-167.002,240-300,308.999
		c-130.996,68.999-132.002,68.999-670.996,73.003l-485.005,3.999L2782.002,2025z"/>
				<path fill="none" d="M4182.001,2025c-7.002-13.999-12.002-30-12.002-35c0-17.998,154.004-276.001,182.998-307.002l31.006-32.998
		h348.994c231.006,0,362.002-3.999,387.002-11.001c55-16.997,130-76.997,165-132.998c27.002-45,30-57.002,30-135
		c0-75-2.998-92.002-27.002-132.002c-28.994-50-78.994-97.998-130.996-126.001c-27.002-15-62.998-18.994-219.004-20.996
		c-268.994-5-265.996,27.998-35.996-370C5002.001,547.998,5130,325.996,5185.996,230l102.002-175l200-2.998L5687.001,50L5700,80
		c12.002,29.004,7.002,40-172.998,354.004l-185,325l57.002,35c123.994,76.992,240.996,226.992,286.992,369.995
		c22.002,67.002,27.002,101.001,28.008,196.001c0.996,135-12.002,196.001-64.004,305c-65,133.999-167.002,240-300,308.999
		c-130.996,68.999-132.002,68.999-670.996,73.003l-485,3.999L4182.001,2025z"/>
				<path fill="none" d="M1695,422.998l-17.998-27.002l86.997-172.998L1850,50h193.999h193.999l13.003,32.002
		c12.998,30.996,10,37.002-72.002,197.002L2095,445l-191.001,2.998L1712.998,450L1695,422.998z"/>
			</g>
		</svg>
		</NavLink>
	)
}

export default Logo
