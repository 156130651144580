import * as React from 'react';
import ServerDetails from "./cards/ServerDetails";
import DiskDetails from "./cards/DiskDetails";
import RamDetails from "./cards/RamDetails";
import CoreDetails from "./cards/CoreDetails";
import ClockDetails from "./cards/ClockDetails";
import UptimeDetails from "./cards/UptimeDetails";
import CpuDetails from "./cards/CpuDetails";
import LastOnlineDetails from "./cards/LastOnlineDetails";
import LastRestartDetails from "./cards/LastRestartDetails";
import OsDetails from "./cards/OsDetails";
import PingDetails from "./cards/PingDetails";

export const PcDashboard = (props) => {
    return (
        <React.Fragment>
            <div className="horizontal-1">
                <ServerDetails delay={50} name={props.loadAnimation ? `server pop-animation ` : `server`}
                               data={props.currentData}/>
                <DiskDetails delay={100} name={props.loadAnimation ? `disk pop-animation ` : `disk`}
                             data={props.currentData}/>
                <RamDetails delay={150} name={props.loadAnimation ? `ram pop-animation ` : `ram`}
                            data={props.currentData}/>
            </div>
            <CoreDetails delay={200} name={props.loadAnimation ? `cores pop-animation ` : `cores`}
                         data={props.currentData}/>
            <ClockDetails delay={250} name={props.loadAnimation ? `clock-speed pop-animation ` : `clock-speed`}
                          data={props.currentData}/>
            <UptimeDetails delay={300} name={props.loadAnimation ? `uptime pop-animation ` : `uptime`}
                           data={props.currentData}/>
            <CpuDetails delay={350} name={props.loadAnimation ? `cpu-load pop-animation ` : `cpu-load`}
                        data={props.currentData}/>
            <LastOnlineDetails delay={400} name={props.loadAnimation ? `last-online pop-animation ` : `last-online`}
                               data={props.currentData}/>
            <LastRestartDetails delay={450} name={props.loadAnimation ? `last-restart pop-animation ` : `last-restart`}
                                data={props.currentData}/>
            <OsDetails delay={50} name={props.loadAnimation ? `os pop-animation ` : `os`} data={props.currentData}/>
            <PingDetails delay={550} name={props.loadAnimation ? `ping pop-animation ` : `ping`}
                         data={props.currentData}/>
        </React.Fragment>
    );
};