import React from 'react'
import styled from '@emotion/styled'
import {formatBytes} from '../../global/Functions';

const Cards = styled('div')`background-color: ${props => props.theme.card};
-webkit-box-shadow:${props => props.theme.shadow}`;

const DiskDetails = (props) => {
    return (
		<Cards style={{transitionDelay: `${props.delay}ms`}} className={`information-cards-container ${props.name}`}>
            <div style={{width:`${props.data.usedDiskSpace / props.data.totalDiskSpace * 100}%`}} className="information-card-percentage"> </div>
            <div className="_3-grid">
 				<div className="main-stat">
					<h3>Total Diskspace</h3>
					<h2>{formatBytes(props.data.totalDiskSpace)}</h2>
				</div>
                <div className="sub-stat_1">
                    <h5>Free Diskspace</h5>
                    <h4>{formatBytes(props.data.totalDiskSpace - props.data.usedDiskSpace)}</h4>
                </div>
                <div className="sub-stat_2">
                    <h5>Used Diskspace</h5>
                    <h4>{formatBytes(props.data.usedDiskSpace)}</h4>
                </div>
            </div>
        </Cards>
    )
};
export default DiskDetails
