import React from 'react'
import styled from '@emotion/styled'
import {
	AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts'
import { useTheme } from '../../../darkmode/ThemeContext'

const data = [
	{
		name: '60 seconds', load: 60,
	},
	{
		load: 80,
	},
	{
		load: 40,
	},
	{
		load: 50,
	},
	{
		load: 30,
	},
	{
		load: 77,
	},
	{
		load: 66,
	},
]

const Cards = styled('div')`background-color: ${props => props.theme.card};
-webkit-box-shadow:${props => props.theme.shadow};
	tspan {
		color:white !important;
		fill:${props => props.theme.font}!important;
		top:10px;
	}
`

const CpuDetails = (props) => {

	const themeState = useTheme()
	const darkGridColor = themeState.dark ? '#400011' : '#FAD2DA'

	return (
		<Cards style={{ transitionDelay: `${props.delay}ms` }} className={`information-cards-container ${props.name}`}>
			<div className="graph-container">
				<div className="main-stat">
					<h3>Cpu Load</h3>
				</div>

				<div className="graph-wrapper">
					<ResponsiveContainer width="80%"  height={500}>
					<AreaChart
						width="99%"  height="99%"
						animationBegin={props.delay}
						data={data}
						margin={{
							top: 10, right: 0, left: 0, bottom: 0,
						}}
					>
						<CartesianGrid strokeDasharray="3 3"/>
						<XAxis dataKey="name"/>
						<YAxis domain={[0, 100]}/>
						<Tooltip/>
						<Area type="monotone" dataKey="load" stroke="#E32249" strokeWidth={2} fill={darkGridColor}/>
					</AreaChart>
					</ResponsiveContainer>
				</div>
			</div>
		</Cards>
	)
}
export default CpuDetails
