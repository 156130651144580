import * as React from 'react';


export const Hamburger = (props) => {
    return (
        <div className="menu-wrapper">
            <div className={props.animate ? "hamburger-menu animate" : "hamburger-menu"}></div>
        </div>


    );
};