import React from 'react'
import styled from '@emotion/styled'

const Cards = styled('div')`background-color: ${props => props.theme.card};
-webkit-box-shadow:${props => props.theme.shadow}`;

const ClockDetails = (props) => {
    return (
		<Cards style={{transitionDelay: `${props.delay}ms`}} className={`information-cards-container ${props.name}`}>
            <div style={{width: `${props.percentage}%`}} className="information-card-percentage"> </div>
            <div className="_1-grid">
				<div className="main-stat">
					<h3>Base Clock Speed</h3>
					<h2>{props.data.clockSpeed === null ? 0 : props.data.clockSpeed} GHZ</h2>
				</div>
            </div>
        </Cards>
    )
};
export default ClockDetails
