import React, {useState} from 'react'
import styled from '@emotion/styled'
import DarkMode from '../general/DarkMode'
import Logo from "./Logo";
import NavLink from "react-router-dom/NavLink";
import {Hamburger} from "./Hamburger";

const HeaderBar = styled('div')`background-color: ${props => props.theme.mobileHeader};
svg{path {fill:  ${props => props.theme.logo};}`;

const Menu = styled('div')`background-color: ${props => props.theme.mobileHeader};`;

const Header = (props) => {

    const [menuOpen, openMenu] = useState(false);
    const switchDashboard = (data, id) => {
        props.updateData(id);
    };

    const switchRoutes = () => {
        openMenu(false);
    };

    const url = window.location.pathname;

        return (
        <HeaderBar className={url === "/dashboard" ? `header-container-mobile grid` : "header-container-mobile"} >
            <div className="hamburger-container" onClick={() => {
                openMenu(!menuOpen)}} >
                <Hamburger animate={menuOpen}/>
            </div>
            {url === "/dashboard" ? (
                <div className="header-dashboard-information">
                    <select onChange={(e) => {
                        switchDashboard(props.data, e.target.value)
                    }} className="styled-select">
                        {props.data.map((data, index) => {
                            return <option value={index} key={data.serviceName}>{data.serviceName}</option>
                        })}
                    </select>
                    <div className={`dashboard-information-status ${props.currentData.online ? "online" : "offline"}`}>
                        <p>{props.currentData.online ? "Online" : "Offline"}</p>
                    </div>
                </div>
            ):
                null
            }
            <div className="header-logo">
                <Logo/>
            </div>
            <Menu className={menuOpen ? "header-menu open" : "header-menu"}>
                <div className="side-nav-menu">
                    <NavLink to="/" activeClassName="selected-link" exact>
                        <li onClick={switchRoutes}>Home
                        </li>
                    </NavLink>
                    <NavLink to="/dashboard" activeClassName="selected-link" exact>
                        <li onClick={switchRoutes}>Dashboard
                        </li>
                    </NavLink>
                </div>
                <div className="header-menu-info">
                    <div className="side-nav-date">
                        <p>Last fetch: {props.date}</p>
                    </div>
                    <div className="side-nav-darkmode">
                        <DarkMode/>
                    </div>
                </div>
            </Menu>
            <div onClick={() => {
                openMenu(false)
            }} className={menuOpen ? "header-overlay open" : "header-overlay"}>
            </div>
        </HeaderBar>
    )
};

export default Header
