import React from 'react'
import styled from '@emotion/styled'

const Cards = styled('div')`background-color: ${props => props.theme.card};
-webkit-box-shadow:${props => props.theme.shadow}`;

const PingDetails = (props) => {
    return (
		<Cards style={{transitionDelay: `${props.delay}ms`}} className={`information-cards-container ${props.name}`}>
            <div className="_2-grid">
				<div className="main-stat">
					<h3>Last Restart</h3>
					<h2>{props.data.lastRestart}</h2>
				</div>
            </div>
        </Cards>
    )
};
export default PingDetails
