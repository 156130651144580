import React from 'react'
import styled from '@emotion/styled'

const Cards = styled('div')`background-color: ${props => props.theme.card};
-webkit-box-shadow:${props => props.theme.shadow}`;

const ServerDetails = (props) => {

    return (
		<Cards style={{transitionDelay: `${props.delay}ms`}} className={`information-cards-container ${props.name}`}>
            <div style={{width: `${props.percentage}%`}} className="information-card-percentage"> </div>
            <div className="_4-grid">
				<div className="main-stat">
					<h3>Server Name</h3>
					<h2>{props.data.serviceName}</h2>
				</div>
                <div className="sub-stat_1">
                    <h5>Mac Adress</h5>
                    <h4>{props.data.macAddress}</h4>
                </div>
                <div className="sub-stat_2">
                    <h5>IP</h5>
                    <h4>{props.data.ip}</h4>
                </div>
				<div className="sub-stat_3">
					<h5>Port</h5>
					<h4>{props.data.port}</h4>
				</div>
            </div>
        </Cards>
    )
};


export default ServerDetails
